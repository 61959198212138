import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import LaterBoardImg from "../../assets/images/get-stuff-done/later-board.webp"
import StaffDoneMImg from "../../assets/images/get-stuff-done/get-stuff-done-m.webp"
import NotificationMImg from "../../assets/images/get-stuff-done/notification-m.webp"
import LaterBoardMImg from "../../assets/images/get-stuff-done/later-board-m.webp"
import KanbanViewMImg from "../../assets/images/get-stuff-done/kanban-view-m.webp"
import { Link } from "gatsby"

const GetStuffDone = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Get Stuff Done | BlueMail App"
        postDescription="Tasks sorts your inbox by which emails require your attention the most and will remind you about an email when you need it."
        postImage="/img/OG/og_image-get-staff-done.png"
        postURL="features-functions/get-stuff-done"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid get-stuff w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  Get Stuff <span>Done</span>
                </h1>
                <p>
                  Tasks sorts your inbox by which emails require your attention
                  the most and will remind you about an email when you need it.
                  There is no need to open a planner anymore. Simply mark the
                  email Later+ and let BlueMail know when you will have time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={StaffDoneMImg} alt="Get stuff done" />
            </div>
          </div>
        </div>
        <div className="container-fluid easy-notification w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>Notifications</span> make it easy
                </h2>
                <p>
                  BlueMail will send you a notification to remind you of an
                  important email. Afterwards, simply mark the email as Done to
                  remove it from your task list.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={NotificationMImg} alt="notification make it easy" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  <span>Later</span> Board
                </h2>
                <p>
                  Don’t have time to deal with a specific email right now?
                  BlueMail's Later Board lets you turn your emails into
                  actionable tasks to help you keep track of them and ensure you
                  complete them on time.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img className="d-992" src={LaterBoardImg} alt="Later board" />
              <img
                className="w-991 mt-3"
                src={LaterBoardMImg}
                alt="Later board mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid kanban-view w-707 r mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  <span>Kanban</span> View
                </h2>
                <p>
                  The Later Board lets you move your emails onto a virtual
                  bulletin board and organize them in Today, Later, or Done
                  columns. It also gives you the option to add custom columns as
                  needed. Once emails are added to the Later Board, you can
                  easily move them from one column to another.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5">
              <img src={KanbanViewMImg} alt="Kanban view" />
            </div>
          </div>
        </div>
        <div className="container">
          <Link to="/blog/later-board/">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mb-100 keep-mb">
                <h2>Start using the Later Board</h2>
                <p>
                  Later Board gives you the ability to turn your emails into
                  tasks to help you keep track of them and ensure you complete
                  them on time. <span> Learn more </span>
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default GetStuffDone

export const query = graphql`
  query GetStuffDonePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
